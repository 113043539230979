<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="search"
          clearable
          outlined
          dense
          height="40px"
          label="Search name, email, or etc."
          prepend-inner-icon="mdi-magnify"
          color="primary"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          auto-select-first
          clearable
          :menu-props="{
            bottom: true,
            offsetY: true,
          }"
          append-icon="mdi-chevron-down"
          v-model="state"
          :items="state_list"
          @change="getCityList"
          item-text="name"
          item-value="id"
          outlined
          dense
          height="40px"
          label="Filter by State"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          auto-select-first
          clearable
          :menu-props="{
            bottom: true,
            offsetY: true,
          }"
          append-icon="mdi-chevron-down"
          v-model="city"
          :items="city_list"
          item-text="name"
          item-value="id"
          outlined
          dense
          height="40px"
          label="Filter by City"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="5" class="text-right">
        <v-btn
          class="text-capitalize px-7"
          color="primary"
          outlined
          height="40px"
          @click="addEca({ show: true, id: '', type: 'add' })"
        >
          <v-icon size="16" left>mdi-plus</v-icon>Add New ECA Body
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="elevation-3"
      :items-per-page="page_count"
      :server-items-length="page_count"
      :loading="loading"
      loading-text="Please wait.... Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
    >
      <template v-slot:[`item.created`]="{ item }">
        {{ $utils.getDateFormat(item.created) }}
      </template>

      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template v-slot:[`item.remark`]="{ item }">
        <div class="">
          <div v-if="item.remark">{{ item.remark }}</div>
          <v-edit-dialog
            @save="onSave(item.id, 'remark')"
            @open="editDialog.remark = item.remark"
            large
            transition="slide-y-transition"
          >
            <div class="primary--text d-flex align-center text-caption">
              <span v-if="item.remark">
                <v-icon small color="primary">mdi-pencil</v-icon>
                Edit Remark
              </span>
              <span v-else>
                <v-icon small color="primary">mdi-plus</v-icon>
                Add Remark
              </span>
            </div>
            <template v-slot:input>
              <div class="my-4">Remark</div>
              <v-textarea
                v-model="editDialog.remark"
                rows="3"
                type="text"
                flat
                outlined
                dense
              ></v-textarea>
            </template>
          </v-edit-dialog>
        </div>
      </template>

      <template v-slot:[`item.contact`]="{ item }">
        <div class="">
          <div v-if="item.user.mobile || item.user.email || item.full_address">
            <div v-if="item.user.mobile">{{ item.user.mobile_display }}</div>
            <div v-if="item.user.email">{{ item.user.email }}</div>
            <div v-if="item.full_address">{{ item.full_address }}</div>
          </div>
          <v-icon v-else>mdi-minus</v-icon>
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-switch
          color="success"
          v-model="item.is_active"
          @change="changeStatus(item.is_active, item.id)"
          :label="item.is_active ? 'Active' : 'In-active'"
        ></v-switch>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            outlined
            class="text-capitalize"
            color="primary"
            height="32px"
            @click="
              addEca({
                show: true,
                type: 'edit',
                id: item.id,
              })
            "
          >
            edit
          </v-btn>
          <v-btn
            small
            outlined
            class="text-capitalize"
            color="primary"
            height="32px"
            @click="
              toggleConfirmationDialog({ show: true, action: 'delete' }),
                (user_id = item.id)
            "
          >
            delete
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>
    <ConfirmationDialog @confirmed="deleteUser" height="130px" width="130px">
      <template v-slot:confirmBtnText> Yes Delete </template>
    </ConfirmationDialog>
    <add-eca @reload-list="getEcaList" />
  </div>
</template>
<script>
import _ from "lodash";

import { mapGetters, mapActions } from "vuex";
import AddEca from "./addEca.vue";

import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog";

export default {
  components: { ConfirmationDialog, AddEca },
  name: "ecaManagement",
  data() {
    return {
      itemsPerPageList: [5, 10, 15, 20, 30, 40, 50],
      page_total: 1,
      page_number: 1,
      page_count: 10,
      total_count: 0,
      items: [],
      search: "",
      user_id: null,
      loading: false,
      headers: [
        {
          sortable: false,
          text: "Date  Joined",
          value: "created",
          width: 120,
        },
        {
          sortable: false,
          text: "Name",
          value: "name",
        },
        {
          sortable: false,
          text: "Contact",
          value: "contact",
          width: 250,
        },
        {
          sortable: false,
          text: "Remark",
          value: "remark",
          width: 250,
        },
        {
          sortable: false,
          text: "Status",
          width: 150,
          value: "status",
        },
        {
          sortable: false,
          text: "Action",
          width: 200,
          value: "action",
        },
      ],
      state: null,
      city_list: [],
      city: null,
      editDialog: {
        cost: 0,
        remark: "",
      },
    };
  },
  watch: {
    search() {
      this.debouncedReloadData();
    },

    state() {
      this.getEcaList();
      this.updateRoute();
    },

    city() {
      this.getEcaList();
      this.updateRoute();
    },

    page_count() {
      this.getEcaList();
      this.updateRoute();
    },

    page_number() {
      this.getEcaList();
      this.updateRoute();
    },
  },

  computed: {
    ...mapGetters({
      state_list: "getStateList",
    }),
    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },
    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },
  },

  created() {
    const query = this.$route.query;

    if (query.page) {
      this.page_number = Number(query.page);
    }

    if (query.search) {
      this.search = query.search;
    }

    if (query.state) {
      this.state = Number(query.state);
    }

    if (query.city) {
      this.city = Number(query.city);
      this.getCityList();
    }

    this.updateRoute();
  },

  mounted() {
    this.getEcaList();
    this.$root.$on("onNavClicked", this.navClickHandler);
  },

  methods: {
    ...mapActions({
      toggleConfirmationDialog: "dialogs/toggleConfirmationDialog",
      setConfirmationDialogBtnLoading:
        "dialogs/setConfirmationDialogBtnLoading",
      addEca: "admin/addEca",
      showSnackbar: "snackBar/showSnackbar",
    }),

    navClickHandler(navKey) {
      if (navKey === "eca_management") {
        this.getEcaList();
        this.updateRoute();
      }
    },

    debouncedReloadData: _.debounce(function () {
      this.getEcaList();
      this.updateRoute();
    }, 1000),

    updateRoute() {
      const query = {};

      if (this.search) {
        query.search = String(this.search);
      }

      if (this.page_number) {
        query.page = Number(this.page_number);
      }

      if (this.city) {
        query.city = Number(this.city);
      }

      if (this.state) {
        query.state = Number(this.state);
      }

      this.$router
        .replace({
          path: this.$route.path,
          query: query,
        })
        .catch((error) => error);
    },

    getCityList() {
      const params = {
        apply_pagination: false,
        state_id: this.state,
      };

      const successHandler = (res) => {
        this.city_list = res.data.data;
      };

      this.$request(this.$keys.GET, this.$urls.master.city.list, {
        params: params,
        onSuccess: successHandler,
      });
    },

    getEcaList() {
      this.loading = true;
      this.items = [];

      const params = {};
      params["page_number"] = this.page_number;
      params["page_count"] = this.page_count;
      if (this.search) params["search"] = this.search;
      if (this.state) params["state_id"] = this.state;
      if (this.city) params["city_id"] = this.city;

      const onSuccess = (res) => {
        this.page_total = res.data.page_info.page_total;
        this.page_number = res.data.page_info.page_number;
        this.total_count = res.data.page_info.total_count;
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      this.$request(this.$keys.GET, this.$urls.eca.list, {
        params,
        onSuccess,
        onFinally,
      });
    },
    deleteUser() {
      const params = {};
      params["eca_body_id"] = this.user_id;

      const onSuccess = (res) => {
        this.toggleConfirmationDialog({ show: false });
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.getEcaList();
      };

      const onFinally = () => {
        this.setConfirmationDialogBtnLoading({ loading: false });
      };

      this.$request(this.$keys.DELETE, this.$urls.eca.delete, {
        params,
        onSuccess,
        onFinally,
      });
    },
    changeStatus(value, id) {
      let params = {};
      params["eca_body_id"] = id;

      let data = {};
      data["is_active"] = value;

      this.$request(this.$keys.PUT, this.$urls.eca.update, {
        params,
        data,
      });
    },
    onEdit(params, data) {
      const onSuccess = () => {
        this.getEcaList();
      };

      this.$request(this.$keys.PUT, this.$urls.eca.update, {
        params,
        data,
        onSuccess,
      });
    },
    onSave(id, field) {
      let data = {};
      let params = {
        eca_body_id: id,
      };

      switch (field) {
        case "remark":
          data["remark"] = this.editDialog.remark;
          this.onEdit(params, data);
          break;
        default:
          break;
      }
    },
  },
};
</script>